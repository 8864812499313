<template>
  <div class="topContainer">
    <div class="wrap-product-details">
      <div class="container mb-5">
        <img src="/img/bck/Piante_sx.png" class="img-fluid plant_sx" />
        <img src="/img/bck/Piante_dx.png" class="img-fluid plant_dx" />
        <div class="row">
          <loading :active.sync="isLoading"></loading>
          <loading :active.sync="isLoadingCart"></loading>
          <div class="container">
            <div class="card bg-white shadow cardDetailProduct" v-if="step1Visible">
              <div class="row">
                <div class="col-sm-12 col-md-5 imgProducts">
                    <img src="/img/00gate.jpg" class="img-fluid img-responsive" />
                    <img src="/img/madeinitaly.jpg" class="img-fluid img-responsive" />
                </div>
                <div class="col-sm-12 col-md-7 p-5 productDetailContent">
                    <b-row>
                      <b-col sm="6">
                        <span class="align-right text-right">vCPU </span>
                      </b-col>
                      <b-col sm="6">
                        <span class="txtbold" v-if="item.features">{{item.features.CORE.value}}   </span>
                      </b-col>
                    </b-row>
                    <b-row>
                    <b-col sm="6">
                      <span class="align-right text-right">GB vRAM</span>
                    </b-col>
                    <b-col sm="6">
                      <span class="txtbold" v-if="item.features">{{item.features.RAM.value}} </span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="6">
                      <span class="align-right text-right">GB vSTO NVMe</span>
                    </b-col>
                    <b-col sm="6">
                      <span class="txtbold" v-if="item.features"> {{item.features.STONVME.value}} </span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="6">
                      <span class="align-right text-right"> IPv4</span>
                    </b-col>
                    <b-col sm="6">
                      <span class="txtbold"v-if="item.features"> {{item.features.IPV4.value}}</span>
                    </b-col>
                  </b-row>
                    <b-row>
                    <b-col sm="6">
                      <span class="align-right text-right" v-if="item.features">{{item.features.TRAFF.key}}</span>
                    </b-col>
                    <b-col sm="6">
                      <span class="txtbold" v-if="item.features">{{item.features.TRAFF.value}} </span>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col sm="6">
                      <span class="align-right text-right"> {{$t('message.bandwidth')}}</span>
                    </b-col>
                    <b-col sm="6">
                      <span class="txtbold" v-if="item.features">{{item.features.BW.value}}</span>
                    </b-col>
                  </b-row>

                  <b-alert show v-if="messageOs" variant="warning">{{messageOs}}</b-alert>
                  <validation-field :label="$t('message.select_os_text')" :v="$v.cartItem.os">
                    <select class="form-control"
                            v-model="cartItem.os"
                            v-on:change="addToCart"
                    >
                      <option
                        :value="itemOs.Related_Item"
                        v-for="(itemOs , i) in itemsOs"
                        v-if="itemOs.Related_Item_Description !== 'Not Available'"
                      >
                        {{itemOs.Related_Item_Description}}
                      </option>
                    </select>
                  </validation-field>

                  <validation-field :label="$t('message.select_cp_text')" :v="$v.cartItem.cp" v-if="showControlPanel">
                    <select class="form-control"
                            v-model="cartItem.cp"
                            v-on:change="addToCart"
                    >
                      <option value="">{{$t('message.no_panel')}}</option>
                      <option
                        :value="controlPanel.Related_Item"
                        v-for="(controlPanel , i) in controlPanels"
                        v-if="controlPanel.Related_Item_Description !== 'Not Available'"
                      >
                        {{controlPanel.Related_Item_Description}}
                      </option>
                    </select>
                  </validation-field>
                  <b-alert show v-if="messageIpv4" variant="warning">{{messageIpv4}}</b-alert>
                  <div v-if="this.ipv4s.Max">
                    <validation-field :label="$t('message.select_additional_ip_text')" :v="$v.cartItem.ipv4">
                      <select class="form-control"
                              v-model="cartItem.ipv4"
                              v-on:change="addToCart"
                      >
                        <option value="">{{$t('message.no_added_ip')}}</option>
                        <option
                          :value="ele"
                          data-sku="010.IPV4"
                          v-for="ele in ipv4s.Max"
                        >
                          {{ele}}
                        </option>
                      </select>
                    </validation-field>
                  </div>
                </div>
              </div>
              <div class="p-lg-3">
                <b-button show block class="bg-theme-secondary" @click="togglePage">
                  <b class="font-2xl" >
                    {{$t('message.go_to_checkout_now_for')}} {{cart.amount | currency('&euro;', 2, { spaceBetweenAmountAndSymbol:
                    true,decimalSeparator: ',' ,thousandsSeparator: '.'})}} <small>{{$t('message.plus_vat')}}</small><br/>
                    <span v-if="this.quotes.length"> <small>{{$t('message.or_learn_how_to_save')}} {{this.quotes[7].pricePerMonth | currency('&euro;', 2, { spaceBetweenAmountAndSymbol: true,decimalSeparator: ',' ,thousandsSeparator: '.'})}} {{$t('message.plus_vat')}}</small></span>
                  </b>
                </b-button>
              </div>
            </div>
            <div class="card bg-white p-5 shadow " v-if="step2Visible && !isLoading">
              <div class="row no-gutters" v-if="quotes.length">
                <div class="col-sm-6">
                  <div class="mt-5 mb-5 text-center">
                    <h3 style="font-size: 18px !important;">{{$t('message.text_choose_payment_time')}}</h3>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="p-4">
                    <div v-if="step2Visible && !step1Visible">
                      <validation-field
                        :label="s"
                        :v="$v.discount"
                        v-if="cartItem.os"
                      >
                        <select
                          class="form-control"
                          v-model="discount"
                          v-on:change="changeDiscount"
                        >
                          <option
                            :key="index"
                            v-bind:selected="discount === itemDiscount.Months"
                            v-bind:value="itemDiscount.Months"
                            v-for="(itemDiscount, index) in itemsDiscounts"
                          >
                            {{itemDiscount.Months}} &nbsp; <span v-if="itemDiscount.Months > 1">{{$t('message.months')}}</span>
                            <span v-else>{{$t('message.month')}}</span>&nbsp; ({{itemDiscount.Discount}}
                            {{$t('message.prefix_of_discount')}})
                          </option>
                        </select>
                      </validation-field>
                    </div>
                  </div>

                </div>
                <div class="col-sm-12">
                  <b-tabs pills card>
                    <b-tab :title="$t('message.vat_excluded')" active>
                      <b-card-text>
                        <span>
                          <table class="table table-responsive table-striped table-sm w-100" v-if="this.quotes.length">
                            <thead>
                              <th>{{$t('message.months') | capitalize}}</th>
                              <th>{{$t('message.discount') | capitalize}}</th>
                              <th>{{$t('message.reference_price') | capitalize}}</th>
                              <th>{{$t('message.at_month') | capitalize}}</th>
                              <th>{{$t('message.monthly_save') | capitalize}}</th>
                              <th>{{$t('message.total_save') | capitalize}}</th>
                              <th>{{$t('message.total_discounted') | capitalize}}</th>
                            </thead>
                            <tbody>
                              <tr v-for="(quote,index) in quotes">
                                <td style="width: 14%">{{quote.months}}</td>
                                <td style="width: 14%">{{quote.discount}} %</td>
                                <td style="width: 14%">{{quote.referencePrice  | currency('&euro;', 2, { spaceBetweenAmountAndSymbol: true,decimalSeparator: ',' ,thousandsSeparator: '.'})}}</td>
                                <td style="width: 14%">{{quote.pricePerMonth | currency('&euro;', 2, { spaceBetweenAmountAndSymbol: true,decimalSeparator: ',' ,thousandsSeparator: '.'})}}</td>
                                <td style="width: 14%">{{quote.savePerMonth | currency('&euro;', 2, { spaceBetweenAmountAndSymbol: true,decimalSeparator: ',' ,thousandsSeparator: '.'})}}</td>
                                <td style="width: 15%">{{quote.saveTotal | currency('&euro;', 2, { spaceBetweenAmountAndSymbol: true,decimalSeparator: ',' ,thousandsSeparator: '.'})}}</td>
                                <td style="width: 15%">{{quote.total | currency('&euro;', 2, { spaceBetweenAmountAndSymbol: true,decimalSeparator: ',' ,thousandsSeparator: '.'})}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </span>
                      </b-card-text>
                    </b-tab>

                    <b-tab :title="$t('message.vat_included')" v-if="quotes.length && quotes[0].isIndividualPerson === '1'">
                      <b-card-text>
                        <span v-if="quotes.length">
                          <table class="table table-responsive table-striped table-sm w-100">
                            <thead>
                              <th>{{$t('message.months') | capitalize}}</th>
                              <th>{{$t('message.discount') | capitalize}}</th>
                              <th>{{$t('message.reference_price') | capitalize}}</th>
                              <th>{{$t('message.at_month') | capitalize}}</th>
                              <th>{{$t('message.monthly_save') | capitalize}}</th>
                              <th>{{$t('message.total_save') | capitalize}}</th>
                              <th>{{$t('message.total_discounted') | capitalize}}</th>
                            </thead>
                            <tbody>
                              <tr v-for="(quote,index) in quotes">
                                <td style="width: 14%">{{quote.months}}</td>
                                <td style="width: 14%">{{quote.discount}} %</td>
                                <td style="width: 14%">{{quote.referencePriceIncludingVat  | currency('&euro;', 2, { spaceBetweenAmountAndSymbol: true,decimalSeparator: ',' ,thousandsSeparator: '.'})}}</td>
                                <td style="width: 14%">{{quote.pricePerMonthIncludingVat | currency('&euro;', 2, { spaceBetweenAmountAndSymbol: true,decimalSeparator: ',' ,thousandsSeparator: '.'})}}</td>
                                <td style="width: 14%">{{quote.savePerMonthIncludingVat | currency('&euro;', 2, { spaceBetweenAmountAndSymbol: true,decimalSeparator: ',' ,thousandsSeparator: '.'})}}</td>
                                <td style="width: 15%">{{quote.saveTotalIncludingVat | currency('&euro;', 2, { spaceBetweenAmountAndSymbol: true,decimalSeparator: ',' ,thousandsSeparator: '.'})}}</td>
                                <td style="width: 15%">{{quote.totalIncludingVat | currency('&euro;', 2, { spaceBetweenAmountAndSymbol: true,decimalSeparator: ',' ,thousandsSeparator: '.'})}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </span>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
              <div class="row" v-if="step2Visible">
                <div class="col-sm-6">
                  <div class="mt-3">
                    <b-button block show class="bg-theme-secondary" @click="togglePage">
                      <span class="font-2xl">{{$t('message.back_to_configuration')}}</span>
                    </b-button>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="mt-3">
                    <router-link :to="{name:'checkout'}" v-if="!isLoadingCart">
                      <b-button block class="bg-theme-secondary" >
                      <span class="font-2xl">{{$t('message.go_to_checkout')}}
                      {{$t('message.for')}} {{cart.amount | currency('&euro;', 2, { spaceBetweenAmountAndSymbol:
                      true,decimalSeparator: ',' ,thousandsSeparator: '.'})}} {{$t('message.plus_vat')}}</span>
                      </b-button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import ApiResolver from '../components/common/ApiResolver';
  import Api from '../components/common/Api';
  import ValidationField from "../components/ui/ValidationField";
  import {email, helpers, required, sameAs, minLength,maxLength, minValue} from 'vuelidate/lib/validators'
  import Cart from "../components/cart/Cart";

  import {mapState} from "vuex";
  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';
  export default {
    name: 'product-detail',
    components: {
      ValidationField,
      Loading
    },
    props: {
      productCode: String,
    },
    data() {
      return {
        item: [],
        itemsDiscounts: [],
        itemsOs: [],
        controlPanels: [],
        ipv4s: [],
        isLoading: false,
        cartItem: {
          vps: this.productCode,
          os: null,
          cp: null,
          ipv4: null,
        },
        step1Visible: true,
        step2Visible: false,
        cartNumber: null,
        oldIpv4: localStorage.getItem('oldIpv4'),
        discount: null,
        discounts: {},
        messageIpv4: '',
        messageOs: '',
        rowCartLocalStorage: {},
        quotes: [],
        actual: {},
      };
    },
    validations: {
      discount: {
        required
      },
      cartItem: {
        os: {
          required
        },
        cp: {},
        ipv4: {}
      },
    },
    methods: {
      togglePage() {
        this.step1Visible = !this.step1Visible;
        this.step2Visible = !this.step2Visible;
      },
      async getQuote(months) {
        this.cartNumber = this.$store.state.cart.number;
        Api
          .get(ApiResolver.get('PAYMENTS') + '/' + this.cartNumber + '/' + 'change-payment-list?month=' + months)
          .then(response => {
            this.quotes.push({...response.data, ...{months: months}});
            this.quotes.sort((a, b) => {
              let x = a['months'];
              let y = b['months'];
              return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            })
          })
          .catch(reason => {
            this.isLoading = false;
            this.$store.dispatch('alerts/setAlert', {
              type: 'danger',
              text: this.$t('message.unable_to_load')
            });
          });
      },
      async changeDiscount() {
        this.isLoading = true;
        await Cart.addDiscount({
          months: this.discount
        })
          .then((response) => {
            this.quotes = response;

            this.isLoading = true;

            this.$store.dispatch('cart/getOrCreate', {
              vps: this.cartItem.vps,
              discount: this.discount,
            });

            this.isLoading = false;
          })
          .catch((error) => {
            //console.log(error);
            Cart.get().then((response) => {
              if (response.Individual_Person === true) {
                this.priceMax = response.Amount_Including_VAT;
              } else {
                this.priceMax = response.Amount;
              }
              this.isLoading = false;
            });
          })
      },
      addToCart() {
        if (!this.$v.cartItem.$invalid) {
          let rowsCart = {
            SalesLines: [
              {
                No: this.cartItem.vps
              },
              {
                No: this.cartItem.os
              }
            ]
          };
          if (this.cartItem.cp && this.cartItem.cp !== 'null') {
            rowsCart.SalesLines.push({
              No: this.cartItem.cp
            })
          }
          if (this.cartItem.ipv4 && this.cartItem.ipv4 !== 'null') {
            rowsCart.SalesLines.push({
              No: '010.IPV4',
              Quantity: this.cartItem.ipv4
            })
          } else {
            rowsCart.SalesLines.push({
              No: '010.IPV4',
              Quantity: 0
            })
          }
          this.isLoading = true;
          Cart.put(rowsCart)
            .then((response) => {
              this.changeDiscount();
            })
            .catch((error) => {
              //console.log(error);
            })
        }
      },
      _fetchDiscounts: function () {
        Api.get(ApiResolver.get('DISCOUNTS', {}))
          .then((response) => {
            this.itemsDiscounts = response.data;
            this.changeDiscount()
          })
          .catch((error) => {
            // handle error
            //console.log(error);
          });
      },
      _fetchCartItems: function () {
        Api.get(ApiResolver.get('PRODUCT', {
          'Code': this.productCode
        }))
          .then((response) => {
            this.item = response.data[Object.keys(response.data)[0]];
            this.isLoading = false;
          })
          .catch((error) => {
            // handle error
            //console.log(error);
            this.isLoading = false;
          });

        Api.get(ApiResolver.get('OPERATING_SYSTEMS', {
          'Code': this.productCode
        }))
          .then((response) => {
            this.itemsOs = response.data;
            let soExist = (this.itemsOs.filter((item) => {
              return item.Related_Item === this.cartItem.os;
            }));
            if (!soExist.length && this.cartItem.os) {
              this.cartItem.os = this.itemsOs[0].Related_Item;
              this.messageOs = 'Il sistema operativo che avevi selezionato, non è disponibile in questa configurazione';
            }
            if (!this.cartItem.os) {
              this.cartItem.os = this.itemsOs[0].Related_Item;
            }
            this.isLoading = false;
            Api.get(ApiResolver.get('MAX_IPV4', {
              'Code': this.productCode
            }))
              .then((response) => {
                this.ipv4s = response.data;
                this.messageIpv4 = '';
                if (this.ipv4s.Max === 0) {
                  this.messageIpv4 = 'Non sono previsti ip aggiuntivi per questa configurazione';
                  this.cartItem.ipv4 = null;
                }
                if (this.ipv4s.Max && this.ipv4s.Max < this.cartItem.ipv4) {
                  this.messageIpv4 = 'Questa configurazione ammette solo ' + this.ipv4s.Max + ' IP aggiuntivi';
                  this.cartItem.ipv4 = this.ipv4s.Max;
                }
                this.addToCart();
                localStorage.setItem('oldIpv4', this.cartItem.ipv4);
                this.isLoading = false;
              })
              .catch((error) => {
                // handle error
                //console.log(error);
                this.isLoading = false;
              });
          })
          .catch((error) => {
            // handle error
            //console.log(error);
          });

        Api.get(ApiResolver.get('CONTROL_PANELS', {
          'Code': this.productCode
        }))
          .then((response) => {
            this.controlPanels = response.data;
            this.isLoading = false;
          })
          .catch((error) => {
            // handle error
            //console.log(error);
            this.isLoading = false;
          });
        Api.get(ApiResolver.get('DISCOUNTS', {}))
          .then((response) => {
            this.itemsDiscounts = response.data;
            if (!this.discount) {
              this.discount = response.data[0].Months;
            }
          })
          .catch((error) => {
            // handle error
            //console.log(error);
          });
      },
      goPrev() {
        this.$router.push({
            name: "productDetail",
            params: {
                productCode: this.$route.params.productCode.replace(/.$/, +(this.productCodeNumber) - 1)
            }
        });
      },
      goNext() {
        this.$router.push({
            name: "productDetail",
            params: {
                productCode: this.$route.params.productCode.replace(/.$/, +(this.productCodeNumber) + 1)
            }
        });
      }
    },
    computed: {
      ...mapState({
        cart: state => state.cart,
        isLoadingCart: state => state.cart.isLoading,
      }),
      productCodeNumber() {
        return this.$route.params.productCode.slice(-1);
      },
      showControlPanel () {
        switch(true) {
          case this.cartItem.os === '151.CEN':
          case this.cartItem.os === '152.UBU':
          case this.cartItem.os === '153.D11':
          case this.cartItem.os === '153.DEB':
          case this.cartItem.os === '152.U22':
          case this.cartItem.os === '155.R8':
            return true;
            default:
              return false;
        }
      }
    },
    async mounted() {
      this.isLoading = true;
      await this.$store.dispatch('cart/getOrCreate', {
        vps: this.cartItem.vps,
        discount: this.discount,
      });
      this.cartItem = this.cart.number;
      let os = this.cart.rows.filter((row) => {
        return row.type === 'os'
      });
      let cp = this.cart.rows.filter((row) => (row.type === 'cp'));
      let ipv4 = this.cart.rows.filter((row) => (row.type === 'ipv4'));
      let cartItem = {
        os: os.length ? os[0].code : null,
        cp: cp.length ? cp[0].code : null,
        ipv4: ipv4.length ? ipv4[0].quantity : null
      };
      this.cartItem = {
        vps: this.productCode,
        os: cartItem.os,
        cp: cartItem.cp,
        ipv4: cartItem.ipv4
      };
      if (this.cart.hasOwnProperty('totals') && this.cart.totals && this.cart.totals.hasOwnProperty('month')) {
        this.discount = this.cart.totals.month.toString();
      }
      this._fetchCartItems();
      //this.getQuoteList();
    },
  };
</script>

<style lang="scss" src="spinkit/scss/spinkit.scss"/>

<style scoped>
  .sk-fading-circle .sk-circle {
    margin: 0 auto;
  }

  input[type="radio"] {
    margin-right: 10px !important;
  }

  .sk-three-bounce {
    height: 40px;
  }
.wrap-arrows .icon-arrow-left,
.wrap-arrows .icon-arrow-right {
    cursor: pointer;
    position: absolute;
    top: 50%;
}
.wrap-arrows .icon-arrow-left {
  left: -50px;
}
.wrap-arrows .icon-arrow-right {
  right: -50px;
}
</style>

<style type="text/css">
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {
  .wrap-product-details {
    background: none !important;
  }
  .plant_sx {
    display: none !important;
  }
  .plant_dx {
    display: none !important;
  }
  .cardDetailProduct {
    box-shadow: none !important;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    left: 50%;
    border: 0px !important;
    transform: translate(-50%, -50%);
  }
  .imgProducts {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .imgProducts img{
    max-width: 150px !important;
  }
  .productDetailContent {
    text-align: center;
  }
}

</style>
